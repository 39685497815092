.login-erp-jd-common-base-page .choice-language-btn {
  color: #666;
  height: 24px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.login-erp-jd-common-base-page .choice-language-btn-active {
  background: rgba(230, 230, 230, 1);
}
.login-erp-jd-common-base-page .erp-account-login {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  border-radius: 4px;
}
.login-erp-jd-common-base-page .erp-account-login:hover {
  color: #fe3e33 !important;
  background: rgba(254, 62, 51, 0.08) !important;
}
.login-erp-jd-common-base-page .jd-account-login:hover {
  border: 1px solid rgba(254, 62, 51, 1) !important;
  color: rgba(254, 62, 51, 1) !important;
}

.login-erp-jd-common-base-page .erp-text {
  border-bottom: 1.2px solid #333;
  line-height: 18px;
}

.login-erp-jd-common-base-page .erp-account-login:hover .erp-text {
  border-bottom: 1.2px solid #fe3e33 !important;
}

.login-erp-jd-common-base-page.mb-login-guide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  color: #333;
  font-size: 16px;
}

.login-erp-jd-common-base-page.mb-login-guide .mb-guide-img {
  width: 227px;
  margin-bottom: 24px;
}
